<template>
    <span>
        <Spelling :text="$t('calendar.date', { year: day.year, date: $t(`calendar.dates.${day.month}`, { day: day.day }) })" />
        <DateLocal :day="day" :inline="inline" />
    </span>
</template>

<script>
export default {
    components: { DateLocal: useLocaleComponent('calendar', 'DateLocal') },
    props: {
        day: { required: true },
        inline: { type: Boolean },
    },
};
</script>
